<template>
    <div class="">
        <b-modal content-class="text-center take-photo-container" id="modal-takephoto-dialog" @show="init()"
                 centered
                 scrollable
                 no-close-on-backdrop
                 hide-footer
                 hide-header>

            <div>
                <div class="row justify-content-end" @click="$bvModal.hide('modal-takephoto-dialog')">
                    <div class="col-auto">
                        <img class="icon-close mb-3" src="../assets/image/icon_close.png">
                    </div>
                </div>

                <div v-show="!img">
                    <vue-web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            width="100%"
                            height="350px"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                    />

                    <div class="row justify-content-center align-items-center mb-3 mt-3">

                        <div class="col" style="position: relative">
                            <div style="position: absolute">
                                <img @click="$refs.selfie_desktop.click()"
                                     class="img-fluid"
                                     style="max-height: 30px; cursor: pointer; margin-top: 15px; margin-left: 15px"
                                     src="../assets/image/icon_album.png">
                                <input ref="selfie_desktop" type="file" @change="selectImage($event)"
                                       accept="image/*;capture=camera"
                                       style="display: none">
                            </div>


                            <img class="img-fluid" style="max-height: 65px; cursor: pointer"
                                 src="../assets/image/icon_camera.png" @click="onCapture">
                        </div>
                    </div>
                </div>

                <div v-show="img">
                    <img :src="img" class="img-fluid w-100 mb-3"/>

                    <div class="row justify-content-center mt-2 mb-2">
                        <div class="col-auto">
                            <div @click="retakePhoto" class="btn-line">RETAKE PHOTO</div>
                        </div>
                        <div class="col-auto">
                            <div @click="save" class="btn-line">CONTINUE</div>
                        </div>
                    </div>

                </div>

            </div>
        </b-modal>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {WebCam} from "vue-web-cam";
    import Swal from 'sweetalert2/src/sweetalert2.js'

    export default {
        name: 'TakePhotoDialog',
        components: {"vue-web-cam": WebCam},
        data() {
            return {
                img: null,
                file: null,
                img_type: null,
                camera: null,
                deviceId: null,
                devices: []
            };
        },
        computed: {
            ...mapState({
                user: state => state.auth.user,
            }),
            device: function () {
                return this.devices.find(n => n.deviceId === this.deviceId);
            }
        },
        methods: {
            init() {
                this.img = null;
                this.file = null;
                this.img_type = null;
                this.camera = null;
                this.deviceId = null;
                this.devices = [];
            },

            selectImage() {
                // Reference to the DOM input element
                let input = event.target;
                // Ensure that you have a file before attempting to read it
                if (input.files && input.files[0]) {


                    const validImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
                    if (!validImageTypes.includes(input.files[0].type)) {
                        return Swal.fire({
                            html: "The file type you are attempting to upload is not allowed.",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn-primary'
                            }
                        });
                    }


                    if (input.files[0].size > 10000000) {
                        return Swal.fire({
                            html: "The uploaded image size is too big, please upload an image not more than 10MB.",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn-primary'
                            }
                        });
                    }


                    // set img params
                    this.file = input.files[0];
                    this.img_type = "image";

                    // create a new FileReader to read this image and convert to base64 format
                    let reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
                        // Read image as base64 and set to imageData
                        this.img = e.target.result;
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsDataURL(input.files[0]);
                }
            },
            onCapture() {
                if (this.deviceId) {
                    this.img = this.$refs.webcam.capture();
                    this.img_type = "base64";
                } else {
                    console.log('not found camera')
                }
            },
            onStarted(stream) {
                console.log("On Started Event", stream);
            },
            onStopped(stream) {
                console.log("On Stopped Event", stream);
            },
            onStop() {
                this.$refs.webcam.stop();
            },
            onStart() {
                this.$refs.webcam.start();
            },
            onError(error) {
                console.log("On Error Event", error);
            },
            onCameras(cameras) {
                this.devices = cameras;
                console.log("On Cameras Event", cameras);
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;
                console.log("On Camera Change Event", deviceId);
            },
            save() {
                this.$emit('takePhoto', {
                    img: this.img,
                    img_file: this.file,
                    img_type: this.img_type
                })
            },
            retakePhoto() {
                this.img = null;
                this.file = null;
                this.img_type = null;
            }
        },
        watch: {
            camera: function (id) {
                this.deviceId = id;
            },
            devices: function () {
                const [first, ...tail] = this.devices;
                if (first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        },
    }
</script>

<style lang="scss">
    @import "../assets/css/colors";

    .take-photo-container {
        color: $primary;
        background-color: $black;
    }
</style>
