<template>
    <div class="">
        <b-modal content-class="text-center selfie-container" id="modal-selfie"
                 centered
                 scrollable
                 no-close-on-backdrop
                 hide-footer
                 hide-header>

            <div>
                <div class="form-group text-right" @click="$bvModal.hide('modal-selfie')">
                    <img class="icon-close" src="../assets/image/icon_close.png">
                </div>

                <div class="form-group">
                    <img class="img-fluid" :src="img_preview"/>
                </div>

                <div class="form-group">
                    {{user.name}}
                </div>
                <hr class="bg-white">

                <div class="form-group">
                    {{user.department}}
                </div>
                <hr class="bg-white">

                <div class="form-group">
                    {{user.email}}
                </div>

                <hr class="bg-white">

                <div class="form-group">
                    <div class="text-center">
                        <b-dropdown
                                v-model="form.message"
                                :text="form.message || 'SELECT YOUR PLEDGE'"
                                variant="transparent"
                                class="input-message w-100">

                            <b-dropdown-item v-for="option in pledges"
                                             :key="option"
                                             :value="option"
                                             @click="form.message = option"
                            >
                                {{option}}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <error-message class="mt-1" :msg="errorMessage.message"></error-message>
                </div>


                <div class=" mb-4 text-center">
                    <div class="form-group">SELECT YOUR BADGE STICKER</div>
                    <hr class="bg-white mb-0">

                    <div class="mb-1">
                        <img class="icon-select" src="../assets/image/down-arrow.png"/>
                    </div>

                    <swiper class="row"
                            @slideChange="setSelectedSlide($event,'selectedSticker')"
                            :options="swiperStickerOptions"
                            ref="stickerSwiper"
                            v-if="!isLoading">
                        <swiper-slide class="col-auto" v-for="sticker in stickers" :key="sticker">
                            <img class="img-fluid" :src="getImgUrl(`image/sticker/${sticker}.png`)"/>
                        </swiper-slide>
                    </swiper>

                    <div class="mt-3 font-weight-normal">
                        Please swipe left or right to choose your sticker
                    </div>
                </div>


                <div class="text-center">
                    <div class="text-center">
                        <div class="btn-line" @click="save" v-if="!isLoading">POST</div>
                        <div v-if="isLoading">
                            <div class="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </b-modal>
    </div>
</template>

<script>
    import store from '../store';
    import {mapState} from 'vuex';
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    import {UPLOAD_SELFIE} from "../store/actions.type";
    import Swal from 'sweetalert2/src/sweetalert2.js'
    import * as _ from 'underscore'
    import ErrorMessage from "./ErrorMessage";
    import validate from 'validate.js';

    export default {
        name: 'SelfieDialog',
        components: {ErrorMessage, Swiper, SwiperSlide},
        computed: {
            ...mapState({
                user: state => state.auth.user,
                stickers: state => state.selfie.sticker,
                pledges: state => state.selfie.pledges,
            })
        },
        props: {
            img_file: File,
            img_preview: String
        },
        data() {
            return {
                isLoading: true,
                form: {
                    message: "",
                    sticker: ""
                },
                selectedSticker: 0,

                swiperStickerOptions: {
                    loop: true,
                    centeredSlides: true,
                    slidesPerView: 3,
                    spaceBetween: 10
                },

                errorMessage: {}
            }
        },
        created() {
            setTimeout(() => {
                this.isLoading = false;
            }, 2000);
        },
        methods: {
            getImgUrl: function (imagePath) {
                return require('@/assets/' + imagePath);
            },

            setSelectedSlide(e, type) {
                this[type] = e.realIndex;
            },

            async save() {
                let errorMessage = this.validateForm();

                if (errorMessage) {
                    return this.errorMessage = errorMessage;
                } else {
                    this.errorMessage = {};
                }

                if (this.isLoading) return;
                this.isLoading = true;

                let result = await store.dispatch(UPLOAD_SELFIE, {
                    image: this.img_file,
                    sticker: this.stickers[this.selectedSticker],
                    message: this.form.message
                });

                this.isLoading = false;

                if (result.status === 'yes') {
                    Swal.fire({
                        html: result.message,
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn-primary'
                        }
                    }).then(() => {

                        this.$bvModal.hide('modal-selfie');

                        let form = JSON.parse(JSON.stringify(this.form));
                        this.$emit('openPledgeDialog', {
                            message: form.message,
                            sticker: this.stickers[this.selectedSticker],
                            profile_image: this.img_preview,
                            name: this.user.name,
                            department: this.user.department,
                            img_type: "base64"
                        });

                        this.form = {
                            message: "",
                            sticker: ""
                        };

                    });

                } else {
                    Swal.fire({
                        html: result.message,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn-primary'
                        }
                    });
                }

            },


            validateForm() {
                let validateRule = null;
                let errMessageList = null;


                validateRule = {
                    message: {
                        presence: {
                            allowEmpty: false,
                            message: "^Please select a pledge"
                        },
                    }
                };

                errMessageList = validate(
                    {
                        message: this.form.message
                    }, validateRule);


                if (errMessageList) {
                    const error = {};
                    for (const [key, value] of Object.entries(errMessageList)) {
                        error[key] = value[0];
                    }
                    return error;
                } else {
                    return false;
                }
            },

        }
    }
</script>

<style lang="scss">
    @import "../assets/css/colors";

    .selfie-container {
        color: $primary;
        background-image: url("../assets/image/backgrond_selfie.png");
        background-position: center center;
        background-size: cover;
        font-weight: bold;
        background-color: $black;
    }

    .selfie-container textarea {
        width: 100%;
        color: $primary;
        text-align: center;
        outline: none;
        box-shadow: none;
        border: none;
        background-color: transparent;
        border-radius: 30px;
        font-weight: bold;
    }


    .selfie-container textarea::placeholder {
        color: $primary;
        font-weight: bold;
    }

    .icon-close {
        max-height: 20px;
        cursor: pointer;
    }

    .modal-body::-webkit-scrollbar {
        width: 3px;
    }

    .modal-body::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 8px;
    }

    .icon-select {
        height: 20px;
        margin-top: -10px;
    }
</style>
