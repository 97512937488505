<template>
    <div>
        <b-modal content-class="pledge-container"
                 id="modal-pledge"
                 size="xl"
                 centered
                 hide-header
                 hide-footer
                 no-close-on-backdrop>


            <div class="pledge-body">

                <!-- sticker card -->
                <div class="sticker-card">
                    <div class='hex gradient-border'>

                        <div class="row justify-content-center align-items-center" style="height: 175px">
                            <div class="col-auto" style="max-width: 200px;">
                                <div class="hex-body">
                                    <img class="img-fluid" style="max-height: 140px;"
                                         :src="`${baseUrl}/sticker/sticker_${data.sticker}.png`">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end sticker card -->


                <div class="selfie-card">
                    <div class="img-selfie"
                         v-bind:style="{ backgroundImage: 'url(' + profileImage + ')' }">
                    </div>
                </div>


                <!-- pledge card -->
                <div class="pledge-card" style="position: relative">
                    <div class="hex gradient-border">
                        <div class="row justify-content-center align-items-center"
                             style="height: 220px;">

                            <div class="col-auto" style="max-width: 200px;">
                                <div class="hex-body">
                                    <div class="text-center">

                                        <div class="message">
                                            “{{data.message}}”
                                        </div>

                                        <div class="name">{{data.name}}</div>
                                        <div class="department">{{data.department}}</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <img class="icon-close" src="../assets/image/icon_close.png"
                         @click="close">
                </div>
                <!-- end pledge card -->


            </div>


        </b-modal>
    </div>
</template>

<script>
    import {API_URL, BASE_URL} from '../config/constant'


    export default {
        name: 'PledgeDialog',
        props: {
            data: Object
        },
        computed: {
            profileImage: function () {
                if (this.data.img_type === 'base64') {
                    return this.data.profile_image;
                } else {
                    return `${this.apiUrl}/assets/uploads/${this.data.profile_image}`;
                }

            }
        },
        data: function () {
            return {
                apiUrl: API_URL,
                baseUrl: BASE_URL,
            }
        },
        methods: {
            close() {
                this.$bvModal.hide('modal-pledge');
            },

        }
    }
</script>

<style lang="scss">
    @import "../assets/css/colors";

    .pledge-container {
        background-color: transparent;
    }

    #modal-pledge___BV_modal_outer_ .modal-backdrop {
        background-color: #003e66 !important;
    }

    .selfie-card {
        z-index: 1;
    }

    .pledge-card {
        margin-left: -40px;

        .hex {
            position: relative;
            height: 220px;
            width: 250px;
            background: #0079b4;
            -webkit-clip-path: polygon(50px 0px, calc(100% - 50px) 0px, 100% 50%, calc(100% - 50px) 100%, 50px 100%, 0px 50%);
        }

        .hex.gradient-bg {
            color: white;
        }

        .hex.gradient-border {
            color: $black;
        }

        .hex:before {
            position: absolute;
            content: '';
            height: calc(100% - 14px); /* 100% - 2 * border width */
            width: calc(100% - 14px); /* 100% - 2 * border width */
            left: 7px; /* border width */
            top: 7px; /* border width */
            -webkit-clip-path: polygon(50px 0px, calc(100% - 50px) 0px, 100% 50%, calc(100% - 50px) 100%, 50px 100%, 0px 50%);
            z-index: -1;
        }

        .hex.gradient-bg:before {
            background: #0079b4;
        }

        .hex.gradient-border:before {
            background: rgb(245, 246, 248);
        }

        .hex-body {
            padding: 8px;
        }
    }

    .sticker-card {
        margin-top: -80px;
        margin-right: -40px;

        .hex {
            position: relative;
            height: 175px;
            width: 210px;
            background: #0079b4;
            -webkit-clip-path: polygon(60px 0px, calc(100% - 60px) 0px, 100% 50%, calc(100% - 58px) 100%, 58px 100%, 0px 50%);
        }

        .hex.gradient-bg {
            color: white;
        }

        .hex.gradient-border {
            color: $black;
        }

        .hex:before {
            position: absolute;
            content: '';
            height: calc(100% - 14px); /* 100% - 2 * border width */
            width: calc(100% - 14px); /* 100% - 2 * border width */
            left: 7px; /* border width */
            top: 7px; /* border width */
            -webkit-clip-path: polygon(55px 0px, calc(100% - 55px) 0px, 100% 50%, calc(100% - 55px) 100%, 55px 100%, 0px 50%);
            z-index: -1;
        }

        .hex.gradient-bg:before {
            background: #0079b4;
        }

        .hex.gradient-border:before {
            background: rgb(245, 246, 248);
        }

        .hex-body {
            padding: 15px;
        }
    }

    .pledge-container .modal-dialog {
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: flex;
        position: fixed;
        z-index: 100000;
    }

    .img-selfie {
        max-height: 250px;
        max-width: 250px;
        border-radius: 50%
    }

    .modal-content {
        border: none;
    }

    .modal-backdrop {
        opacity: 0.8;
    }

    .pledge-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .pledge-container .icon-close {
        float: right;
        position: absolute;
        right: 0;
        top: 0;
    }


    @media (max-width: 620px) {
        .pledge-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }


        .sticker-card {
            margin-top: 0;
            margin-bottom: -25px;
            margin-right: 0;
        }

        .pledge-card {
            margin-top: -25px;
            margin-left: 0;
        }

        .img-selfie {
            max-height: 200px;
            max-width: 200px;
            border-radius: 50%
        }
    }

    .pledge-container .img-selfie {
        width: 250px;
        height: 250px;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
        margin: 3px;
    }

    .pledge-container {
        font-family: "Helvetica Neue", sans-serif;

        .name {
            color: #0079b4;
            font-weight: bold;
            line-height: 1;
            max-height: 35px;
            overflow: hidden;
        }

        .department {
            color: #00aee1;
            font-weight: bold;
            line-height: 1.2;
            font-size: 14px;
        }
    }

    .pledge-container .message {
        justify-content: center;
        display: flex;
        align-items: center;
        min-height: 120px;
        color: #4d4d4d;
        font-weight: 600;
        line-height: 1.3;
    }


</style>
